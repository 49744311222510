<template>
  <!-- 상단 버튼 -->
  <v-container fluid>
    <v-row
      no-gutters
    >
      <v-col  
        v-for="j in rows"
        :key="j"
        :class="rows === 1 ? 'd-flex justify-end' : 'd-flex justify-space-between'"
      >
        <v-btn     
          v-for="k in 6/rows"
          :key="k"
          text
          color="primary"
          small 
          class="font-weight-bold"
          @click="btnFnc(j === 2 && k === 1 ? 4 : j*k-1)"
        >
          <v-icon left> 
            {{ icon[j === 2 && k === 1 ? 4 : j*k-1] }} 
          </v-icon>
          {{ btnName[j === 2 && k === 1 ? 4 : j*k-1] }}
        </v-btn>        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      btnName: [
        '',
        'btn1',
        'btn2',
        'btn3',
        'btn4',
        'btn5',
      ],
      icon: [
        '',
        'mdi-pencil',
        'mdi-plus',
        'mdi-minus',
        'mdi-pencil',
        'mdi-pencil',
      ],
    }),

    computed: {
      rows() {
        const { xs } = this.$vuetify.breakpoint
        return xs ? 3 : 1
      },       
    },

    methods: {
      btnFnc(idx) {
        if ( idx === 0 ) this.fnc1();
        else if ( idx === 1 ) this.fnc2();
        else if ( idx === 2 ) this.fnc3();
        else if ( idx === 3 ) this.fnc4();
        else if ( idx === 4 ) this.fnc4();
      },
    },
  }  
</script>